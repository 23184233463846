<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Master Diagnosa</strong>
              </h5>
            </template>
            <!-- <b-row>
                <b-col cols="12" md="12" lg="12">
                  <b-button variant="primary" v-b-modal.modal-input-diagnosa
                    ><CIcon name="cil-plus" /> Tambah Data</b-button
                  >
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" md="12" lg="12">
                  <hr />
                </b-col>
              </b-row> -->

            <b-row>
              <b-col cols="4" md="4" lg="4">
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Kode Diagnosa
                  </template>
                  <b-form-input v-model="dataFilter.kd_diag"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4" md="4" lg="4">
                <b-form-group label-cols-md="3">
                  <template v-slot:label>
                    Nama Diagnosa
                  </template>
                  <b-form-input v-model="dataFilter.nm_diag"></b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="4" md="4" lg="4">
                <b-form-group>
                  <b-button
                    style="width: 30%"
                    @click="getDatas()"
                    variant="success"
                    >Cari
                  </b-button>
                </b-form-group>
              </b-col>
              
            </b-row>

            <b-row class="mt-3">
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  responsive
                  show-empty
                  small
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(actions)="item">
                      <b-button
                        variant="warning"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Edit Data'"
                        v-b-modal.modal-edit-diagnosa
                        @click="is_data = item.item"
                        ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                      >
  
                      <b-button
                        variant="danger"
                        class="mr-1"
                        v-c-tooltip.hover.click="'Hapus Data'"
                        v-b-modal.modal-delete-diagnosa
                        @click="is_data = item.item"
                        ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                      >
                    </template> -->
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <b-form-group
                  label="Per Halaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="md"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="5" offset-md="5">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <!-- <ModalInput @alertFromChild="triggerAlert($event), getDatas()" />
    <ModalEdit
      :data_edit="is_data"
      @alertFromChild="triggerAlert($event)"
      @tutupModal="getDatas()"
    />
    <ModalDelete
      :data_delete="is_data"
      @alertFromChild="triggerAlert($event), getDatas()"
    /> -->
  </div>
</template>

<script>
// import ModalDelete from "./modalDelete.vue";
// import ModalInput from "./modalInput.vue";
// import ModalEdit from "./modalEdit.vue";

export default {
  components: {
    // ModalInput,
    // ModalDelete,
    // ModalEdit,
  },
  name: "ms_diagnosa",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      is_data: "",
      dataFilter: {
        kd_diag: "",
        nm_diag: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "kd_diag",
          label: "Kode Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nm_diag",
          label: "Nama Diagnosa",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "spesialis",
          label: "Spesialis",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        //   {
        //     key: "actions",
        //     label: "Actions",
        //     class: "table-option-2 text-center",
        //   },
      ],
      items: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      // filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  watch: {
    'currentPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getDatas()
      }
    },
    'perPage'(newVar, oldVar){
      if(newVar != oldVar){
        this.getDatas()
      }
    },
  },
  mounted() {
    // Set the initial number of items
    // this.totalRows = this.items.length;
    this.getDatas();
  },
  methods: {
    // onFiltered(filteredItems) {
    //   // Trigger pagination to update the number of buttons/pages due to filtering
    //   this.totalRows = filteredItems.length;
    // },
    async getDatas() {
      this.tableBusy = true;
      let vm = this
      let diagnosa = await vm.$axios.post("/ms_diagnosa/list", {
        halaman : vm.currentPage - 1 + '', 
        jumlah : vm.perPage + '',
        ...vm.dataFilter,
      });
      // console.log(diagnosa, "ini diagnosa");
      vm.items = []
      vm.items = diagnosa.data.status == 200 ? diagnosa.data.data.map((x, idx) => {
        x.no = idx + 1 + (vm.currentPage - 1) * vm.perPage
        if (x.non_spesialis == true) {
          x.spesialis = "Tidak";
        } else {
          x.spesialis = "Ya";
        }
        return x
      }) : []
      vm.totalRows = parseInt(diagnosa.data.count);
      vm.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
